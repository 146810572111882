import { Deal } from '@finn/platform-modules';
import { SERVER_DATE_FORMAT } from '@finn/ua-constants';
import dayjs from 'dayjs';
import * as German from 'dayjs/locale/de';
import * as English from 'dayjs/locale/en';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { getHolidays } from 'feiertagejs';
import { FormatDateOptions } from 'react-intl';

import { HandoverDates, SwapHandoverOption } from '~/services/handover';
import { dayjsDate } from '~/types/time';

dayjs.extend(isBetween);
dayjs.extend(CustomParseFormat);

// 1st August 2021 => 01.08.2021
export const COMMON_DATE_FORMAT: FormatDateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

// 1st August 2021 => 1. August
export const DAY_LONG_MONTH_FORMAT: FormatDateOptions = {
  day: 'numeric',
  month: 'long',
};

// 1st August 2021 => 1. August 2021
export const DAY_LONG_MONTH_YEAR_FORMAT: FormatDateOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

// 1st August 2021 => August 2021
export const LONG_MONTH_YEAR_FORMAT: FormatDateOptions = {
  month: 'long',
  year: 'numeric',
};

// 1st August 2021 => 1.Aug.2021
export const DAY_SHORT_MONTH_YEAR_FORMAT: FormatDateOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

const getDateLocale = (lang: string) => (lang === 'de' ? German : English);

const addSeconds = (date: dayjsDate, seconds: number) => {
  return dayjs(date).add(seconds, 'second').toDate();
};

export const isBusinessDay = (date: dayjsDate) => {
  const workingWeekdays = [1, 2, 3, 4, 5];
  if (workingWeekdays.includes(dayjs(date).day())) return true;

  return false;
};

export const isWeekend = (date: dayjsDate) => {
  return !isBusinessDay(date);
};

const toUTC = (date: Date): Date => {
  return dayjs(date).add(-date.getTimezoneOffset(), 'minute').toDate();
};

export const range = (
  fromDate: Date,
  toDate: Date,
  iterateFn: (date: Date, interval: number) => Date = addSeconds,
  interval: number = 1
): Array<Date> => {
  const result = [];
  let currentDate = fromDate;
  while (currentDate <= toDate) {
    result.push(currentDate);
    currentDate = iterateFn(currentDate, interval);
  }

  return result;
};

export const getGermanDateFormat = (date: dayjsDate) =>
  dayjs(date).format('DD.MM.YYYY');

export const getUSDateFormat = (date: dayjsDate) =>
  dayjs(date).format('MM/DD/YYYY');

export const formatDateLocale = (date: dayjsDate, locale: string) => {
  if (locale.toLowerCase().includes('us')) {
    return getUSDateFormat(date);
  }

  return getGermanDateFormat(date);
};

const getGermanDateFormatLong = (date: dayjsDate) =>
  dayjs(date).locale('de').format('dddd, DD.MM.YYYY');

const getUSDateFormatLong = (date: dayjsDate) =>
  dayjs(date).format('dddd, MM/DD/YYYY');

export const formatDateLocaleLong = (date: dayjsDate, locale: string) => {
  if (locale.toLowerCase().includes('us')) {
    return getUSDateFormatLong(date);
  }

  return getGermanDateFormatLong(date);
};

export const fromEuropeFormatToDate = (value: string): Date =>
  dayjs(value, 'DD.MM.YYYY').toDate();

export const reverseAbilipayDate = (date: string) =>
  dayjs(date, 'DD.MM.YYYY').toDate();

export const parseUTCDate = (date: string) =>
  dayjs(date, 'YYYY-MM-DD').toDate();

// Get UTC date and ignore the hours
export const getUTCDate = (date: dayjsDate) => {
  const format = 'YYYY-MM-DD';
  const formattedDate = dayjs(date).format(format);
  const finalDate = toUTC(dayjs(formattedDate, format).toDate());

  return finalDate.toUTCString();
};

// Get the Diff between 2 dates in ms
export const dateDiff = (firstDate: Date, secondDate: Date) => {
  // Day in ms
  const day = 1000 * 60 * 60 * 24;

  const firstDateMs = firstDate.getTime();
  const secondDateMs = secondDate.getTime();

  const diff = secondDateMs - firstDateMs;

  return Math.round(diff / day);
};

export const getAgeInYears = (birthday: Date) => dayjs().diff(birthday, 'year');

export const getCurrentMonthInShort = (lang: string = 'de') => {
  try {
    return dayjs().locale(getDateLocale(lang)).format('MMM');
  } catch (e) {
    console.error(e);
  }
};

export const getCurrentYear = () => dayjs().year();

export const getDateMonthWeekends = (date: Date): Date[] => {
  const monthDays = dayjs(date).daysInMonth();

  const weekends = [];

  for (let i = 1; i <= monthDays; i++) {
    const dayInMonth = new Date(date.getFullYear(), date.getMonth(), i);

    if (isWeekend(dayInMonth)) {
      weekends.push(dayInMonth);
    }
  }

  return weekends;
};

export const formatDate = (
  date: dayjsDate,
  dateFormat: string,
  lang: string = 'de'
) => {
  try {
    return dayjs(date).locale(getDateLocale(lang)).format(dateFormat);
  } catch (e) {
    console.error(e);
  }
};

export const getKeyDates = (
  carHandoverAvailability?: HandoverDates,
  swapHandoverOption?: SwapHandoverOption | null
): {
  minDate: Date | undefined;
  maxDate: Date | undefined;
  freeDeliveryDate: Date | undefined;
  closestSwapDate: Date | undefined;
} => {
  if (!carHandoverAvailability) return null;
  let minDate = new Date();
  let maxDate = new Date();
  const availableDates = Object.keys(carHandoverAvailability).map((date) =>
    dayjs(date).toDate().getTime()
  );
  if (availableDates.length) {
    const earliestAvailableDate = new Date(
      Math.min.apply(null, availableDates)
    );
    const latestAvailableDate = new Date(Math.max.apply(null, availableDates));

    minDate = earliestAvailableDate;
    maxDate = latestAvailableDate;
  }

  const freeDateObj = Object.entries(carHandoverAvailability).find(
    (item) => item?.[1]?.delivery_fee === 0
  );

  const freeDeliveryDate = freeDateObj
    ? dayjs(freeDateObj[0]).toDate()
    : undefined;

  let closestSwapDate = undefined;

  if (swapHandoverOption?.subscription_end_date) {
    const subscriptionEndDate = dayjs(swapHandoverOption.subscription_end_date)
      .add(6, 'hour') // add bias towards next day instead of previous day while picking the closest date
      .toDate()
      .getTime();

    const closestSwapDateTimestamp = availableDates.reduce((prev, current) =>
      Math.abs(prev - subscriptionEndDate) <
      Math.abs(current - subscriptionEndDate)
        ? prev
        : current
    );

    closestSwapDate = closestSwapDateTimestamp
      ? new Date(closestSwapDateTimestamp)
      : undefined;
  }

  return { minDate, maxDate, freeDeliveryDate, closestSwapDate };
};

export const isWithinRange = (date: Date, start: Date, end: Date) => {
  return date >= start && date <= end;
};

export const addBusinessDays = (date: dayjsDate, number: number) => {
  const numericDirection = number < 0 ? -1 : 1;
  let currentDay = dayjs(date);
  let daysRemaining = Math.abs(number);

  while (daysRemaining > 0) {
    currentDay = currentDay.add(numericDirection, 'd');

    if (isBusinessDay(currentDay)) daysRemaining -= 1;
  }

  return currentDay.toDate();
};

export const getAge = (birthday: Date) =>
  dayjs(new Date()).diff(birthday, 'year', true);

export const isPublicHolidayDE = (date: dayjsDate) => {
  const formattedDate = dayjs(date).format('YYYY-MM-DD');
  const year = dayjs(formattedDate).get('year');
  const publicHolidaysDE = getHolidays(year, 'ALL');

  let isHoliday = false;
  publicHolidaysDE.forEach((day) => {
    if (day.dateString === formattedDate) {
      isHoliday = true;
    }
  });

  return isHoliday;
};

export const getClosestWorkingDay = (receivedDate: dayjsDate) => {
  let date = receivedDate;
  while (isPublicHolidayDE(date)) {
    date = dayjs(date).add(1, 'day');
  }

  return date;
};

// return min and max dates in an array of string dates
export const getMinMaxDates = (dates: string[]) => {
  const arrtime = dates.map((str) => new Date(str).getTime());
  const minDate = new Date(Math.min(...arrtime));
  const maxDate = new Date(Math.max(...arrtime));

  return { minDate, maxDate };
};

export const isDateBeforeMinDate = ({
  date,
  minDate,
}: {
  date: Date;
  minDate: Date;
}) => {
  let diff = 0;
  try {
    // diff in hours. For timezone issue we get 2 hours diff for the same date
    diff = (minDate.getTime() - date.getTime()) / 3600000;
  } catch {
    // do nothing
  }

  // if diff is more than 12 hours, we consider it as different date
  return diff > 12;
};

export const getReversedDate = (
  date: string | undefined,
  propertyName: string
) => {
  return date ? { [propertyName]: reverseAbilipayDate(date) } : {};
};

export const parseEndDate = (subscription: Deal) => {
  const endDate = dayjs(subscription?.end_date);

  return dayjs(endDate, { format: SERVER_DATE_FORMAT });
};

export const getEndInDays = (subscription: Deal) => {
  return dateDiff(new Date(), parseEndDate(subscription).toDate());
};
