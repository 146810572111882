import { LoginModal, SpacingWrapper } from '@finn/platform-modules';
import { DEFAULT_DOWN_PAYMENT } from '@finn/ua-constants';
import { Header } from '@finn/ua-header';
import { ModalContainer, ModalKey } from '@finn/ua-modals';
import { Breadcrumb, ErrorBoundary } from '@finn/ui-components';
import { BasePageData, MinimisedFooterData } from '@finn/ui-cosmic';
import { isMobileApp } from '@finn/ui-utils';
import React, { ReactNode } from 'react';

import Footer from '~/components/Footer';
import CheckoutFooter from '~/components/Footer/CheckoutFooter';
import { CartButton } from '~/modules/checkout/components/CartButton/CartButton';
import { getCheckoutCartUrl } from '~/utils/checkout';

import { useConfigureStore } from '../components/ConfigurePage/ConfigurePageStore';
import MinimisedFooter from '../components/Footer/MinimisedFooter';

type Props = {
  data: BasePageData;
  checkoutFooter?: boolean;
  hideHeaderFooter?: boolean;
  isMobile?: boolean;
  children?: ReactNode;
  footerBreadcrumbs?: Breadcrumb[];
  onBreadcrumbClick?: (breadcrumb: Breadcrumb, index: number) => void;
};

const DefaultLayout = ({
  children,
  data,
  checkoutFooter,
  hideHeaderFooter,
  isMobile,
  footerBreadcrumbs,
  onBreadcrumbClick,
}: Props) => {
  const headerData = data.metadata.header;
  const footerData = data.metadata.footer;

  const {
    downPayment,
    kilometerPackage,
    term,
    vehicle: configureVehicle,
  } = useConfigureStore();

  const downPaymentAmount = downPayment ? DEFAULT_DOWN_PAYMENT : undefined;

  if (isMobile || isMobileApp() || hideHeaderFooter) {
    return (
      <div className="relative min-h-screen">
        {/*
          we want to mount invisible header, as we want to have
          some modals available that rendered inside header
        */}
        <div className="hidden">
          <ErrorBoundary>
            <Header data={headerData} CartButton={CartButton} />
          </ErrorBoundary>
        </div>
        <ModalContainer
          modalKey={ModalKey.LOGIN}
          ModalComponent={LoginModal}
          cartURL={getCheckoutCartUrl(
            configureVehicle?.id ?? '',
            term,
            kilometerPackage,
            downPaymentAmount
          )}
        />
        {children}
      </div>
    );
  }

  const isB2BPageFooter = footerData?.slug?.includes?.('b2b');

  return (
    <div className="relative min-h-screen">
      <ErrorBoundary>
        <Header data={headerData} CartButton={CartButton} />
      </ErrorBoundary>
      <ModalContainer
        modalKey={ModalKey.LOGIN}
        ModalComponent={LoginModal}
        cartURL={getCheckoutCartUrl(
          configureVehicle?.id ?? '',
          term,
          kilometerPackage,
          downPaymentAmount
        )}
      />
      {children}
      {checkoutFooter && (
        <ErrorBoundary>
          <div className="pb-24 sm:pb-16" />
          <CheckoutFooter />
        </ErrorBoundary>
      )}
      {isB2BPageFooter && (
        <ErrorBoundary>
          <SpacingWrapper>
            <MinimisedFooter
              data={footerData as unknown as MinimisedFooterData}
            />
          </SpacingWrapper>
        </ErrorBoundary>
      )}
      {!checkoutFooter && !isB2BPageFooter && (
        <ErrorBoundary>
          <Footer
            data={footerData}
            breadcrumbs={footerBreadcrumbs}
            onBreadcrumbClick={onBreadcrumbClick}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default DefaultLayout;
